import { LoaderFunctionArgs, MetaFunction, json } from '@remix-run/node';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';

import { ErrorComponent } from '~/components/features/error/error-component.tsx';
import { SetTimeZone } from '~/components/timezone/set-time-zone.tsx';

import { env } from '~/utils/env/env.server.ts';
import { PublicEnv, publicEnvSchema } from '~/utils/env/public-env.tsx';
import i18next from '~/utils/i18next/i18next.server';
import { getUserFromSession } from '~/utils/session/authentication.server.ts';
import { getPreferenceSession } from '~/utils/session/user-preference-session.server.ts';
import { getTimezoneCookie } from '~/utils/timezone/timezone.server.ts';

import { findBrandingForOrganization } from '~/models/organizations.server.ts';
import { hashBranding } from '~/modules/branding/utils.ts';
import { getCurrentOrganizationCookie } from '~/modules/context.server.ts';

import './inter.css';
import './serif.css';
import './tailwind.css';
import { cn } from './utils/css/css';
import { withSentry } from '@sentry/remix';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import process from 'node:process';
import { NuqsAdapter } from 'nuqs/adapters/remix';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getToast } from 'remix-toast';
import { Toaster, toast } from 'sonner';

export const handle = {
  i18n: ['common'],
};
export const meta: MetaFunction = () => {
  return [{ title: 'Elterndashboard | Triargos' }];
};

function loadPublicEnv() {
  return publicEnvSchema.parse({
    IS_PRODUCTION: process.env.NODE_ENV === 'production',
    PUBLIC_SENTRY_DSN: env.PUBLIC_SENTRY_DSN,
  });
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const currentOrganizationId = await getCurrentOrganizationCookie(request);
  const user = await getUserFromSession(request);
  const [preferenceSession, branding, { headers, toast }, timezone, locale] = await Promise.all([
    getPreferenceSession(request, user?.id),
    currentOrganizationId ? findBrandingForOrganization(currentOrganizationId) : null,
    getToast(request),
    getTimezoneCookie(request),
    i18next.getLocale(request),
  ]);
  const publicEnv = loadPublicEnv();
  return json(
    {
      locale,
      brandingLastModified: branding?.updatedAt.getTime(),
      appearance: preferenceSession.getAppearance(),
      preferences: preferenceSession.toObject(),
      timezone,
      toast,
      publicEnv,
    },
    {
      headers,
    },
  );
};
const queryClient = new QueryClient();

function App() {
  const {
    toast: toastData,
    locale,
    brandingLastModified,
    appearance,
    publicEnv,
  } = useLoaderData<typeof loader>();
  const { i18n } = useTranslation();
  useEffect(() => {
    if (toastData) {
      switch (toastData.type) {
        case 'success': {
          toast.success(toastData.message);
          break;
        }
        case 'error': {
          toast.error(toastData.message);
          break;
        }
        case 'info': {
          toast.info(toastData.message);
          break;
        }
      }
    }
  }, [toastData]);

  return (
    <QueryClientProvider client={queryClient}>
      <html lang={locale} dir={i18n.dir()} className={cn('font-inter h-full')}>
        <head>
          <meta charSet='utf-8' />
          <meta
            content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
            id='viewport'
            name='viewport'
          />
          <Meta />
          <Links />
          <link rel={'stylesheet'} href={`/resources/theme.css?v=${brandingLastModified}`} />
        </head>
        <body className={cn(appearance, 'h-full')}>
          <SetTimeZone />
          <Toaster theme={appearance} />
          <NuqsAdapter>
            <Outlet />
          </NuqsAdapter>
          <ScrollRestoration />
          <Scripts />
          <PublicEnv publicEnv={publicEnv} />
        </body>
      </html>
    </QueryClientProvider>
  );
}

export default withSentry(App);
export const ErrorBoundary = () => {
  return (
    <html lang={'en'}>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
      </head>
      <body>
        <ErrorComponent />
      </body>
    </html>
  );
};
